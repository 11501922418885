import * as React from "react";
import * as styles from "./terms-conditions.module.css";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const TermsConditions: FunctionComponent = () => {
  return (
    <Layout>
    <div className={styles.termsConditions}>
      <div className={styles.docTemplateDiv}>
        <div className={styles.textColumnDiv}>
          <div className={styles.titleDiv}>
            <b className={styles.displayB}>Terms and Conditions</b>
          </div>
          <div className={styles.subtitleLargeDiv}>
            <div className={styles.displayDiv}>
              Welcome to Global Care Supply!
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                This website is operated by Global Care Supply. We offer this
                website, including all information, tools, and Services
                available from this site to you, the user, conditioned upon your
                acceptance of all terms, policies, and notices stated here.
              </div>
            </div>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                By visiting our site and/or purchasing products from us, you
                engage in our “Service”. You agree to be bound by the following
                terms and conditions, including additional policies referenced
                herein and/or available by hyperlink. Do not continue to use
                Global Care Supply if you do not agree to the guidelines and
                standards stated on this page.
              </div>
            </div>
          </div>
          <div className={styles.subtitleLargeDiv}>
            <div className={styles.displayDiv}>Cookies</div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Types of Data Collected</div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We use cookies – text files sent to us by your computer – to
                store certain information. Through the use of cookies, we
                authenticate your identity and determine your access to our
                website. Cookies allow us to enable better website visits,
                personalization, security, and for targeted advertising. We also
                use cookies for the analysis of our website services and
                performances.
              </div>
            </div>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                You will be asked to consent to cookies as you open our website.
                However, if you wish to disable this feature, you can do so by
                going through your browser option and settings. Cookies are
                personally assigned to your computer and only read by a domain
                service issued to your device.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>License</div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Global Care Supply owns the intellectual property rights for all
                material established on the website. All images, graphics,
                trademarks, designs, and texts are owned by Global Care Supply
                or the properly attributed party.
              </div>
            </div>
            <div className={styles.pDiv5}>
              <div className={styles.captionDiv}>
                <p className={styles.youShouldNot}>You should not:</p>
                <p className={styles.youShouldNot}>&nbsp;</p>
                <ul className={styles.modifyAnyMaterialsContained}>
                  <li className={styles.modifyAnyMaterials}>
                    Modify any materials contained in this site
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    Claim any of our intellectual property as your own creation
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    Reproduce any portion of our service
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    Duplicate, copy, or sell our service
                  </li>
                </ul>
                <p className={styles.youShouldNot}>&nbsp;</p>
                <p className={styles.thisAgreementShall}>
                  This Agreement shall begin on the date hereof.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>
              Hyperlinking to our Content
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                The following organizations may link to our website without
                prior written approval:
              </div>
            </div>
            <div className={styles.pDiv5}>
              <div className={styles.captionDiv}>
                <ul className={styles.modifyAnyMaterialsContained}>
                  <li className={styles.modifyAnyMaterials}>
                    Government agencies
                  </li>
                  <li className={styles.modifyAnyMaterials}>Search engines</li>
                  <li className={styles.modifyAnyMaterials}>
                    News organizations
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    Online directory distributors
                  </li>
                  <li>System-wide Accredited Businesses</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                These organizations may link to our home page, publications or
                other Website information so long as the link:
              </div>
            </div>
            <div className={styles.pDiv5}>
              <div className={styles.captionDiv}>
                <ul className={styles.modifyAnyMaterialsContained}>
                  <li className={styles.modifyAnyMaterials}>
                    Not in any way deceptive.
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    Not falsely imply sponsorship, endorsement or approval of
                    the linking party and its products and/or services.
                  </li>
                  <li>
                    It fits within the context of the linking party’s site.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We only approve link requests from these organizations if it is
                proven that
              </div>
            </div>
            <div className={styles.pDiv5}>
              <div className={styles.captionDiv}>
                <ul className={styles.modifyAnyMaterialsContained}>
                  <li className={styles.modifyAnyMaterials}>
                    the link would not make us look unfavorably at ourselves or
                    our accredited businesses
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    the organization does not have any negative records with us
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    the benefit to us from the visibility of the hyperlink
                    compensates for the absence of Global Care Supply
                  </li>
                  <li>
                    the link is in the context of general resource information
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.pDiv1}>
              <div className={styles.captionDiv}>
                Global Care Supply’s logo or other artwork will not be allowed
                for usage if linking is absent, and the process is not in
                accordance with the license agreement.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Links to Other Websites</div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Our Service may contain links to third-party websites or
                services that are not owned or controlled by Global Care Supply.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Global Care Supply has no control over and assumes no
                responsibility for any third-party websites or services'
                content, privacy policies, or practices. You further acknowledge
                and agree that Global Care Supply shall not be responsible or
                liable, directly or indirectly, for any damage or loss caused or
                alleged to be caused by or in connection with the use of or
                reliance on any such content, goods, or services available on or
                through any such web sites or services.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We strongly advise you to read the terms and conditions and
                privacy policies of any third-party websites or services that
                you visit.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Termination</div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We may terminate or suspend your access immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if you breach these Terms and Conditions.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Upon termination, your right to use the Service will cease
                immediately. Note that it is our right to allow any user access
                to our website. This could mean revoking your key at any time
                without notice. If necessary, we will block your IP address to
                prevent further visits to our website and use of our Service.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Your Privacy</div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <span> Please read <a className={styles.caption} href="/privacy-policy">privacy policy.</a></span>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Reservation of Rights</div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We reserve the right to remove all links to our website. As you
                read this document, you approve of executing the action upon our
                request immediately.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                We also reserve the right to change these terms and conditions,
                as well as its linking policies. If a revision is necessary, we
                will notify you of the changes at least 30 days prior to any new
                terms taking effect.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                By continuing to access or use Our Service after those revisions
                become effective, you agree to be bound by the revised terms. If
                You do not agree to the new terms, in whole or part, please stop
                using the website and the Service.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Disclaimer</div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                To the maximum extent permitted under applicable law, Global
                Care Supply, on its own behalf and on behalf of its Affiliates
                and its and their respective licensors and service providers,
                expressly disclaims all warranties, whether express, implied,
                statutory or otherwise, with respect to the Service, including
                all implied warranties of merchantability, fitness for a
                particular purpose, title and non-infringement, and warranties
                that may arise out of the course of dealing, course of
                performance, usage or trade practice.
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Without limiting the preceding, neither Global Care Supply nor
                any of the company's providers makes any representation or
                warranty of any kind, express or implied:
              </div>
            </div>
          </div>
          <div className={styles.textbodyDiv17}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <ul className={styles.modifyAnyMaterialsContained}>
                  <li className={styles.modifyAnyMaterials}>
                    As to the operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    That the Service will be uninterrupted or error-free
                  </li>
                  <li className={styles.modifyAnyMaterials}>
                    As to the accuracy, reliability, or currency of any
                    information or content provided through the Service
                  </li>
                  <li>
                    The Service, its servers, the content, or e-mails sent from
                    or on behalf of the Company are free of viruses, scripts,
                    trojan horses, worms, malware, timebombs or other harmful
                    components.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Consent</div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                You hereby consent to our Terms and Conditions of Use by using
                our website.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv2}>Contact Us</div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <span>
                  <span>
                    If you have any questions about these Terms and Conditions,
                    you can
                  </span>
                  <b className={styles.privacyPolicyB}>{` `}</b>
                </span>
                <b className={styles.caption}>
                  <span> <a href="/">contact us</a></span>
                </b>
                <b className={styles.span}>.</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default TermsConditions;

export const Head = () => (
  <SEO title="Global Care Supply | Terms and Conditions" description="" />
)
