// extracted by mini-css-extract-plugin
export var caption = "terms-conditions-module--caption--d656d";
export var captionDiv = "terms-conditions-module--captionDiv--22fac";
export var displayB = "terms-conditions-module--displayB--f6cfa";
export var displayDiv = "terms-conditions-module--displayDiv--39538";
export var displayDiv2 = "terms-conditions-module--displayDiv2--4dd93";
export var docTemplateDiv = "terms-conditions-module--docTemplateDiv--a783e";
export var modifyAnyMaterials = "terms-conditions-module--modifyAnyMaterials--dc741";
export var modifyAnyMaterialsContained = "terms-conditions-module--modifyAnyMaterialsContained--4605c";
export var pDiv = "terms-conditions-module--pDiv--816aa";
export var pDiv1 = "terms-conditions-module--pDiv1--3d3a0";
export var pDiv5 = "terms-conditions-module--pDiv5--b1500";
export var privacyPolicyB = "terms-conditions-module--privacyPolicyB--0b777";
export var span = "terms-conditions-module--span--0f4b8";
export var subtitleDiv = "terms-conditions-module--subtitleDiv--c1590";
export var subtitleLargeDiv = "terms-conditions-module--subtitleLargeDiv--1f816";
export var termsConditions = "terms-conditions-module--termsConditions--96519";
export var textColumnDiv = "terms-conditions-module--textColumnDiv--cca08";
export var textbodyDiv = "terms-conditions-module--textbodyDiv--ec122";
export var textbodyDiv17 = "terms-conditions-module--textbodyDiv17--bbacc";
export var textbodyDiv6 = "terms-conditions-module--textbodyDiv6--ca3e4";
export var thisAgreementShall = "terms-conditions-module--thisAgreementShall--2b0b6";
export var titleDiv = "terms-conditions-module--titleDiv--16ee8";
export var youShouldNot = "terms-conditions-module--youShouldNot--6267f";